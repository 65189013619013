function getDate(time) {
  let resultDate = {}
  if (!time) {
    // 获取当前时间
    resultDate.year = new Date().getFullYear()
    resultDate.month = new Date().getMonth() + 1
    resultDate.day = new Date().getDate()
    resultDate.week = new Date().getDay()
    resultDate.hour = new Date().getHours()
    resultDate.minutes = new Date().getMinutes()
    resultDate.second = new Date().getSeconds()
  } else {
    // 获取指定时间
    resultDate.year = new Date(time).getFullYear()
    resultDate.month = new Date(time).getMonth() + 1
    if (resultDate.month < 10) resultDate.month = '0' + resultDate.month
    resultDate.day = new Date(time).getDate()
    if (resultDate.day < 10) resultDate.day = '0' + resultDate.day
    resultDate.week = new Date(time).getDay()
    resultDate.hour = new Date(time).getHours()
    if (resultDate.hour < 10) resultDate.hour = '0' + resultDate.hour
    resultDate.minutes = new Date(time).getMinutes()
    if (resultDate.minutes < 10) resultDate.minutes = '0' + resultDate.minutes
    resultDate.second = new Date(time).getSeconds()
    if (resultDate.second < 10) resultDate.second = '0' + resultDate.second
  }
  switch (resultDate.week) {
    case 0:
      resultDate.week = '星期日'
      break
    case 1:
      resultDate.week = '星期一'
      break
    case 2:
      resultDate.week = '星期二'
      break
    case 3:
      resultDate.week = '星期三'
      break
    case 4:
      resultDate.week = '星期四'
      break
    case 5:
      resultDate.week = '星期五'
      break
    case 6:
      resultDate.week = '星期六'
      break
    default:
      break
  }
  return resultDate
}
export function getDateformat(time) {
  let date = new Date(time * 1000)
  let year = date.getFullYear()
  let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  let d = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  let h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  let m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return year + '-' + month + '-' + d + ' ' + h + ':' + m + ':' + s
}
export default {
  getDate
}
