//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDateformat } from '@/util/getDate';
import { routes } from '../router/index';
export default {
  data() {
    return {
      is0pen: !0,
      activeIndex: 0,
      activeIndex1: 0,
      is_form: '',
      shwoPageName: '',
      socket: null,
      socketArr: [],
      path: 'wss://digitalizesocket.cnweisou.net?access_token=',
      audio: null,
      end_time: null,
      showEnd_time: !0,
      msgCount: 0,
      showSwitch: !1,
      siteList: [],
      routers: [],
    };
  },
  watch: {
    $route: {
      handler(val) {
        this.shwoPageName = val.meta.name;
        this.is_form = val.meta.form;
        this.$store.state.utils.is_update = 0;
        if (val.path == '/index') {
          this.showEnd_time = !0;
          let timer = setInterval(() => {
            if (this.$refs.rightBot && this.$refs.rightBot.flag) {
              this.msgCountMethod();
              clearInterval(timer);
            }
          }, 10);
        } else {
          this.showEnd_time = !1;
        }
        if (val.path == '/freight' || val.path == '/customerService/addQuickReply' || val.path == '/customerService/addQADB') {
          this.activeIndex1 = -1;
        }
        this.handleMenu();
      },
      immediate: true,
    },
  },
  created() {
    this.routers = this.handelMenu();
    this.handleMenu();
    // if (typeof WebSocket === 'undefined') {
    //   this.$message.error('您的浏览器不支持socket');
    // }
    // let token = localStorage.getItem('token');
    // this.path += token;
    // this.routers.map(item => {
    //   if (item.path == '/') {
    //     this.socket = new WebSocket(this.path);
    //     this.socket.onopen = this.open;
    //   }
    // });
  },
  mounted() {
    if (this.socket) {
      this.audio = this.$refs.audio;
      this.socket.onmessage = this.getMessage;
    }
    this.end_time = getDateformat(Number(localStorage.getItem('uniac_endtime')));
    let a = document.createElement('div');
    a.id = 'download';
    document.body.appendChild(a);
    a.click();
    let aDom = document.getElementById('download');
    document.body.removeChild(aDom);
  },
  computed: {
    // 登录的用户信息
    userInfo() {
      return this.$store.getters.userInfo;
    },
    // 不显示的菜单
    relationMenu() {
      return this.$store.getters.relationMenu;
    },
  },
  methods: {
    handelMenu() {
      let menuLeft = routes.filter(i => i.meta.rules);
      let newArray = [];
      menuLeft.forEach((now, index) => {
        newArray[index] = now;
        newArray[index].children = now.children.filter(item => item.meta.rules);
        newArray[index].children.forEach(now => {
          if (now.children) {
            now.children = now.children.filter(item => item.meta.rules);
          }
        });
      });
      return newArray;
    },
    handleCommand(val) {
      if (val == 'outLogin') {
        this.outLogin();
      }
    },
    // 开启websocket
    open() {
      console.log('开启web');
    },
    msgCountMethod(index) {
      if (index === undefined) {
        this.msgCount = this.$refs.rightBot.msgNotification.length;
      } else {
        this.msgCount = index;
      }
    },
    // websocket 监听消息
    getMessage(data) {
      console.log(JSON.parse(data.data));
      let res = JSON.parse(data.data);
      if (res.type != undefined) {
        let path1 = this.toDealMsg(res.type);
        this.$set(res, 'path', path1);
        this.socketArr.push(res);
        if (this.$route.path == '/index') {
          console.log(res);
          this.$refs.rightBot.msgNotification.push({ content: res.message, create_time: res.create_time, type: res.type });
          this.msgCountMethod();
        }
        if (res.type == 1) {
          this.audio.play();
          this.msgBox(res);
        }
      }
    },
    // 筛选去处理的跳转路径
    toDealMsg(index) {
      if (index == 6) {
        this.$message({
          message: '暂未开放客服模块',
          type: 'warning',
        });
        return '';
      }
      let path2 = '';
      switch (index) {
        case 1:
          path2 = '/order/orderList?tab_type=state_pay';
          break;
        case 2:
          path2 = '/order/salesRefund';
          break;
        case 3:
          path2 = `/store/storeManagement?id=${index.store_id}`;
          break;
        case 4:
          path2 = `/store/reflectAudit?id=${index.store_id}`;
          break;
        case 5:
          path2 = '/set/weixin?from=1';
          break;
        case 6:
          path2 = '';
          break;
        case 7:
          path2 = '/goods/GoodsEvaluate';
          break;
        case 8:
          path2 = '/user/userList';
          break;
        case 9:
          path2 = '/user/userList';
          break;
        case 10:
          path2 = '/user/userList';
          break;
        default:
          break;
      }
      return path2;
    },
    // 右侧弹出消息提示
    msgBox(res) {
      let that = this;
      const notify = this.$notify({
        duration: 3000,
        dangerouslyUseHTMLString: true,
        message: `<div class='msgBox' style='display:flex;justify-content:space-between;align-items: center;'><div><img style='width:40px;height:40px;border-radius:50%' src='${
          res.avatar
        }' /></div><div style=" max-width:270px; margin:0 24px 0 20px;display: flex;flex-direction: column;justify-content: space-around;"><div style='margin-bottom:5px'><b>【${
          res.type == 1 ? '新订单通知' : ''
        }】</b></div><div style='margin-left:4px;text-align:left;font-size:14px'>
        <p>用户[<span style='color:#5654F3'>${res.nickname.substring(0, 1)}***${res.nickname.substring(res.nickname.length - 1)}</span>]下了新的订单,请及时处理！</p>
        <p>订单号:${res.message.substring(14, 36)}</p>
        </div></div><div style='font-size:12px;flex-shrink: 0;text-align: center;width: 56px;height: 28px;line-height: 28px;color:#fff;background:#5654F3;cursor: pointer;' id='btnDeal' >处理<div></div>`,
      });
      // 给通知中的按钮注册事件
      notify.$el.querySelector('#btnDeal').onclick = () => {
        notify.close();
        that.$router.push({
          path: `${res.path}`,
        });
      };
    },
    // 处理菜单
    handleMenu() {
      let that = this;
      let routers = this.routers;
      routers.map((item, index) => {
        item.children.map((items, indexs) => {
          if (items.name == that.$route.name) {
            that.activeIndex = index;
            that.activeIndex1 = indexs;
            if (that.$route.path == '/index') {
              that.is0pen = !1;
            } else {
              that.is0pen = !0;
            }
          }
          if (this.relationMenu.includes(that.$route.name)) {
            if (items.name == that.$route.meta.relation) {
              console.log(index);
              that.activeIndex = index;
              that.activeIndex1 = indexs;
              if (that.$route.path == '/index') {
                that.is0pen = !1;
              } else {
                that.is0pen = !0;
              }
            }
          }
        });
      });
    },
    // 选择子菜单
    selectChildMenu(data, i) {
      let utils = this.$store.state.utils;
      if (utils.is_update) {
        this.$confirm('未保存，是否离开？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          utils.is_update = 0;
          if (i != this.activeIndex1) {
            this.$router.push(data.path);
            this.$store.state.utils.is_record = !1;
          } else {
            if (data.path != this.$route.path) this.$router.push(data.path);
          }
        });
      } else {
        if (i != this.activeIndex1) {
          this.$router.push(data.path);
          this.$store.state.utils.is_record = !1;
        } else {
          if (data.path != this.$route.path) this.$router.push(data.path);
        }
      }
    },
    // 选择一级菜单
    selectMenu(i) {
      let data = this.routers[i];
      let utils = this.$store.state.utils;
      if (utils.is_update) {
        this.$confirm('未保存，是否离开？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          utils.is_update = 0;
          if (i != this.activeIndex) {
            this.$router.push(data.path);
            this.activeIndex = i;
            this.activeIndex1 = 0;
            this.$store.state.utils.is_record = !1;
          } else {
            if (data.path != this.$route.path) this.$router.push(data.path);
          }
          if (this.$route.path == '/index') {
            this.is0pen = !1;
          } else {
            this.is0pen = !0;
          }
        });
      } else {
        if (i != this.activeIndex) {
          this.$router.push(data.path);
          this.activeIndex = i;
          this.activeIndex1 = 0;
          this.$store.state.utils.is_record = !1;
        }
        if (this.$route.path == '/index') {
          this.is0pen = !1;
        } else {
          this.is0pen = !0;
        }
      }
    },
    //退出登录
    outLogin() {
      // this.socket.close = this.close;
      this.$store.dispatch('user/outLogin');
    },
    // 关闭websocket
    close() {
      console.log('socket服务已经关闭');
    },
  },
};
