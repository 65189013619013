import axios from '../../util/axios'
import router from '../../router'
import api from '../../util/api'
import { Message, MessageBox } from 'element-ui'
import { resetVuex } from '@/util/util'
import cookies from 'vue-cookies'
const default_state = {
  menuLeft: [],
  userInfo: {},
  shopInfo: {},
  relationMenu: [],
  loadingFlag: false
}
const state = {
  menuLeft: [],
  userInfo: {},
  shopInfo: {},
  relationMenu: [],
  loadingFlag: false
}

const mutations = {
  resetState(state) {
    let data = Object.assign({}, default_state)
    for (let i in data) {
      state[i] = data[i]
    }
  },
  setRoutes(state, data) {
    state.menuLeft = data
  },
  setRelationMenu(state, data) {
    state.relationMenu = data
  },
  getUserInfo(state, data) {
    state.userInfo = data || {}
  },
  getShopInfo(state, data) {
    state.shopInfo = data || {}
  },
  changeLoadingFlag(state, data) {
    state.loadingFlag = data
  }
}

const actions = {
  outLogin({ commit }, data = {}) {
    MessageBox.confirm('确认退出登录吗？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      axios.post(api.user.logout).then((res) => {
        if (res.code == 0) {
          if (res.code == 0) {
            Message({
              message: '退出登录成功',
              type: 'success'
            })
            resetVuex()
            localStorage.removeItem('token');
            router.push('/login')
            if (data.success) data.success()
          }
        } else {
          Message.error(res.msg)
        }
      })
    })
  },
  backProgram({ commit }, data = {}) {
    axios.post(api.user.backProgram, data).then((res) => {
      if (res.code == 0) {
        resetVuex(['utils'])
        router.push('/shop')
      } else {
        Message.error(res.msg)
      }
    })
  },
  getUserInfo({ commit }) {
    axios.post(api.user.userInfo).then((res) => {
      if (res.code == 0) {
        commit('getUserInfo', res.result)
      } else {
        Message.error(res.msg)
      }
    })
  },
  getShopInfo({ commit }) {
    axios.post(api.user.shopInfo).then((res) => {
      if (res.code == 0) {
        commit('getShopInfo', res.result.info)
      } else {
        Message.error(res.msg)
      }
    })
  },
  get_menu({ commit }, data = {}) {
    axios.post(api.auths.get_menu).then((res) => {
      if (res.code == 0) {
        let arr = []
        let list = res.result
        for (let i in list) {
          let children = list[i].childs || []
          let obj = {
            path: list[i].auth_key,
            meta: {
              icon: list[i].auth_icon,
              title: list[i].auth_meta,
              name: list[i].auth_name,
              rules: 1
            },
            children: []
          }
          for (let y in children) {
            obj.children.push({
              path: children[y].auth_key,
              name: children[y].auth_name,
              meta: {
                rules: 1,
                name: children[y].auth_name
              }
            })
          }
          arr.push(obj)
        }
        arr.push({
          path: '/service',
          name: 'service',
          meta: {
            name: '服务',
            title: '服务管理',
            rules: 1
          },
          children: [
            {
              path: '/service/list',
              name: 'service-list',
              meta: {
                name: '服务列表',
                rules: 1
              }
            },
            {
              path: '/service/addService',
              name: 'service-addService',
              meta: {
                name: '添加服务',
                rules: 1,
                form: 1
              }
            },
            {
              path: '/service/type',
              name: 'service-type',
              meta: {
                name: '服务分类',
                rules: 1
              }
            },
            {
              path: '/service/order',
              name: 'service-order',
              meta: {
                name: '服务订单',
                rules: 1
              }
            },
            {
              path: '/service/afterSale',
              name: 'service-afterSale',
              meta: {
                name: '服务售后',
                rules: 1
              }
            },
            {
              path: '/service/evaluate',
              name: 'service-evaluate',
              meta: {
                name: '服务评价',
                rules: 1
              }
            }
          ]
        })
        arr.push({
          path: '/rights',
          name: 'rights',
          meta: {
            name: '权益',
            title: '权益管理',
            rules: 1
          },
          children: [
            {
              path: '/rights/settledList',
              name: 'rights-settledList',
              meta: {
                name: '入驻列表',
                rules: 1
              }
            },
            {
              path: '/rights/advertisementApply',
              name: 'rights-advertisementApply',
              meta: {
                name: '广告申请',
                rules: 1
              }
            },
          ]
        })
        arr[7].children.push({
          path: '/extension/forum',
          name: 'extension-forum',
          meta: {
            name: '论坛管理',
            rules: 1
          }
        }, {
          path: '/extension/MarketingList',
          name: 'extension-MarketingList',
          meta: {
            name: '智能营销',
            rules: 1
          }
        })
        arr[11].children.push({
          path: '/set/rightSet',
          name: 'set-rightSet',
          meta: {
            name: '权益设置',
            rules: 1
          }
        })
        commit('setRoutes', arr)
        if (arr.length) data.success()
      } else {
        Message.error(res.msg)
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
