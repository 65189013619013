const api = {
  user: {
    login: '/Login/login',
    logout: '/Login/logout',
    userInfo: '/Login/self',
    addFiles: '/Upload/addFiles',
    imgList: '/image/list',
    shopInfo: '/Store/info', //店铺信息
  },
  member: {
    addGroup: '/UserOpenCluster/add',
    groupList: '/UserOpenCluster/list',
    groupInfo: '/UserOpenCluster/info',
    groupEdit: '/UserOpenCluster/edit',
    groupDel: '/UserOpenCluster/delete',
    getGroupNum: '/UserOpenCluster/getClusterNum',
    getRecommend: '/UserOpenCluster/getRecommend',
    editUser: '/UserOpen/edit'
  },
  //权限
  auths: {
    add: '/auths/add', //添加菜单权限
    getAll: '/auths/getAll', //获取菜单列表
    update: '/auths/update', //编辑菜单权限
    getOne: '/auths/getOne', //获取菜单详情
    get_menu: '/auths/get_menu', //获取对应账号的菜单
    get_tree_list: '/auths/get_tree_list', //获取总菜单树
    addRoles: '/Roles/add', //添加角色
    editRoles: '/Roles/update'
  },
  goods: {
    goodsClass: '/GoodsClassify/list',
    addGoods: '/Goods/add',
    addType: '/GoodsClassify/add',
    list: '/Goods/list',
    editInfo: '/Goods/editInfo', //编辑商品信息
    delType: '/GoodsClassify/del',
    delGoods: '/Goods/del',
    editGoods: '/Goods/edit',
    editType: '/GoodsClassify/edit',
    goodsInfo: '/Goods/info',
    shiftGoods: '/Goods/shiftGoods',
    commentList: '/GoodsComment/list', //评价列表
    reply: '/GoodsComment/reply', //回复
    setStatus: '/GoodsComment/setStatus', //评价显示隐藏
    delComment: '/GoodsComment/del', //删除评价
    setPutaway: '/Goods/setPutaway', //设置上架\下架
    goodsStatus: '/Goods/goodsStatus', //商品状态
    isSelect: '/Goods/isSelect', //是否有默认运费模块
    export: '/Goods/export', //导出商品
    import: '/Goods/import', //导入商品
    copyGoods: '/Goods/copyGoods', //复制商品
    exportGoodsClass: '/GoodsClassify/export', //导出商品分类
    wxCode: '/Goods/wxCode',
    setFreight: '/Goods/setFreight',
    setClassify: '/Goods/setClassify',
    groupGoodsList: '/Group/groupGoodsList'
  },
  order: {
    setExpires: '/Shop/setOrderExpires', //设置订单过期时间
    list: '/order/list', //获取订单列表
    info: '/order/info', //获取订单详情
    setSend: '/order/setSend', //订单发货接口
    num: '/order/stateNum', //订单数量
    express: '/express/list', //物流信息
    logisticsQuery: '/Order/logisticsQuery', //物流信息查询
    setSystemMessage: '/Order/setSystemMessage', //添加系统备注信息
    changePrice: '/Order/changePrice', //修改订单价格
    cancel: '/Order/cancel', //取消未付款订单
    cancelList: '/Orderrefundreason/list', //退款原因
    printOrder: '/Order/printOrder', //打印订单
    handleRefund: '/Orderrefund/handle', //处理退款
    salesList: '/Orderrefund/list', //售后列表
    refundInfo: '/Orderrefund/info', //退款详情
    receive: '/Orderrefund/receive', //商家收货
    refund: '/Orderrefund/refund', //原路退回到钱包
    confirmReceipt: '/order/confirmReceipt', //订单确认收货
    refundNum: '/Orderrefund/stateNum', //退款订单数量
    editTracking: '/Order/editTracking' //编辑物流
  },
  store: {
    storeList: '/Store/list', //门店列表
    addStore: '/Store/add', //添加门店
    editStore: '/Store/edit', //编辑门店
    delStore: '/Store/del', //删除门店
    editAccount: '/Store/editAccount', //对账管理
    goodsList: '/Store/goodsList', //商品管理列表
    storeUsersList: '/StoreUsers/list', //店员列表
    addStoreUsers: '/StoreUsers/add', //添加店员
    editStoreUsers: '/StoreUsers/edit', //编辑店员
    delStoreUsers: '/StoreUsers/del', //删除店员
    goodsAuditList: '/Store/storeGoodsAuditList', //商品审核列表
    goodsAudit: '/Store/storeGoodsAudit', //商品审核
    goodsAuditLog: '/Store/storeGoodsAuditLog', //商品审核详情
    withdrawLog: '/Store/withdrawLog',
    getStatus: '/Store/getStatus',
    withdrawLogInfo: '/Store/withdrawLogInfo',
    withdrawnList: '/Store/withdrawnList',
    upStatus: '/Store/upStatus',
    correlationGoods: '/Store/correlationGoods',
    StoreUsersedit: '/StoreUsers/edit',
    StoreUsersinfo: '/StoreUsers/info',
    checkInStatus: '/StoreSettled/settledStatus',
    StoreSettled: '/StoreSettled/list',
    SettledupStatus: '/StoreSettled/upStatus',
    StoreExport: '/Store/export',
    withdrawLogExport: '/Store/withdrawLogExport',
    storeErCode: '/Store/wxCode'
  },
  set: {
    editVideo: '/Upload/editVideo', // 添加视频信息
    freightAdd: '/Freight/add', //添加运费
    freightList: '/Freight/list', //运费列表
    freightDel: '/Freight/del', //删除运费
    freightEdit: '/Freight/edit', //编辑运费
    wxCode: '/WxCode/getPreAuthorizationUrl', //获取微信授权
    wxShopSetting: '/ShopSetting/add', //微信支付配置
    wxAuditStatus: '/WxCode/getAuditStatus', //微信审核状态
    submitAudit: '/WxCode/submitAudit', //提交审核
    wxundocodeaudit: '/WxCode/undocodeaudit', //微信小程序审核撤回
    rejectList: '/WxCode/getThirdLog', // 获取驳回原因列表
    wxtesterBind: '/WxCode/testerBind', //wx绑定体验者
    wxtesterUnbind: '/WxCode/testerUnbind', //wx解绑体验者
    testerList: '/WxCode/testerList', // 体验者列表
    wxRelease: '/WxCode/release', //wx小程序发布
    wxCommit: '/WxCode/commit', //wx提交审核
    wxqrCode: '/WxCode/qrCode', //wx获取体验码
    wxApp: '/WxCode/getWxapp', // wx设置
    setWxapp: '/WxCode/setWxapp', // 设置微信设置
    Wxticket: '/api/Wxticket/handleAuthorize', //微信授权
    addArticleClass: '/ArticleClassify/add', //添加文章分类
    listArticleClass: '/ArticleClassify/list', //文章分类列表
    editArticleClass: '/ArticleClassify/edit', //编辑文章分类
    delArticleClass: '/ArticleClassify/del', //删除文章分类
    addArticle: '/Article/add', //添加文章
    editArticle: '/Article/edit', //编辑文章
    delArticle: '/Article/del', //删除文章
    listArticle: '/Article/list', //文章列表
    address: '/Shipper/list', //地址
    addAddress: '/shipper/add', //添加地址
    editAddress: '/shipper/edit', //编辑地址
    delAddress: '//shipper/del', //删除地址
    setDefaultAddress: '/shipper/setDefault', //设置默认地址
    setDefaultFreight: '/Freight/setDefault', //设置默认运费
  },
  //推广
  push: {
    addCoupon: '/Coupon/add', //添加优惠券
    couponList: '/Coupon/list', //优惠券列表
    couponEdit: '/Coupon/edit', //编辑优惠券
    couponEel: '/Coupon/del', //删除优惠券
    editStatus: '/Coupon/editStatus', //编辑优惠券状态
  },
  data: {
    survey: '/Statistics/overview', //概况
    analysis: '/Statistics/transactionAnalysis', //分析
    goodsAnalyse: '/Goods/goodsAnalyse', //商品分析
    goodsData: '/Goods/goodsData', //商品数据
    goodsRankingList: '/Goods/goodsRankingList', //商品排行榜
    goodsRankingListExport: '/Goods/goodsRankingListExport', //导出商品排行榜
    dataOverView: '/NewStatistics/dataOverView',
    goodsAnalyse1: '/GoodsStatistics/goodsAnalyse',
    salesAnalyse: '/GoodsStatistics/salesAnalyse',
    abnormalGoods: '/GoodsStatistics/abnormalGoods',
    goodsDetail: '/GoodsStatistics/goodsDetail',
    ankingList: '/GoodsStatistics/ankingList',
    storeRankingList: '/GoodsStatistics/storeRankingList',
    refundGoods: '/GoodsStatistics/refundGoods',
    getThirtyData: '/NewStatistics/getThirtyData',
    successRefund: '/NewStatistics/successRefund',
    conversion: '/NewStatistics/conversion',
    realTimeOverview: '/NewStatistics/realTimeOverview',
    todayTrend: '/NewStatistics/todayTrend',
    store_transaction: '/NewStatistics/store_transaction',
    getRefundBlock: '/NewStatistics/getRefundBlock'
  },
  // 首页
  homeIndex: {
    indexData: '/Statistics/quantity',
    setBaseInfo: '/Shop/setBaseInfo',
    readMsg: '/shop/readMessage',
    tabsMessage: '/Message/search',
    typeList: '/Message/typeList',
    unReadCount: '/Message/unReadCount'
  },
  // 上传文件
  upload: {
    addCert: '/Upload/addCert'
  },
  finance: {
    RechargeOrder: '/Recharge/order', //充值
    RechargeGetList: '/Recharge/getList', //获取短信充值列表
    getStatus: '/Recharge/getStatus' //获取支付状态
  },
  // 素材中心
  sourceCenter: {
    ImagesGroupList: '/ImagesGroup/list', //所有图片分组
    ImagesGroupSort: '/ImagesGroup/sort', //图片分组排序
    ImagesGroupAdd: '/ImagesGroup/add', // 添加图片分组
    ImagesGroupEdit: '/ImagesGroup/edit', // 修改图片分组
    ImagesGroupDel: '/ImagesGroup/del', // 删除图片分组
    ImageMove: '/Image/move', // 移动图片分组
    ImageDel: '/Image/dels', // 删除图片
    UpdateName: '/Image/rename', //修改图片名字
    JwtList: '/JwtImages/JwtList', // 站点外获取图片分类
    rename: '/ImagesGroup/rename', // 修改文件夹名字
    moveGroup: '/ImagesGroup/move' // 移动文件夹
  },
  //分销
  distribution: {
    goodsList: '/DistributionGoodsSet/list',
    goodsDetail: '/DistributionGoodsSet/info',
    goodsSet: '/DistributionGoodsSet/edit',
    goodsCancel: '/DistributionGoodsSet/cancel',
    classifyList:'/DistributionGoodsSet/classifyList'
  },
  //同城版接口
  samecity: {
    //服务类
    service: {
      classifyList: '/SamecityService/classifyList',
      addClassify: '/SamecityServiceClassify/add',
      editClassify: '/SamecityServiceClassify/edit',
      delClassify: '/SamecityServiceClassify/del',
      serviceList: '/SamecityService/list',
      addService: '/SamecityService/add',
      serviceInfo: '/SamecityService/info',
      editService: '/SamecityService/edit',
      delService: '/SamecityService/del',
      editSort: '/SamecityService/editSort',
      orderList: '/ServiceOrder/list',
      orderNum: '/ServiceOrder/stateNum',
      orderInfo: '/ServiceOrder/info',
      comment: '/SamecityServiceComment/list',
      orderRefundList: '/ServiceOrderRefund/list',
      orderRefundNum: '/ServiceOrderRefund/stateNum',
      orderRefundInfo: '/ServiceOrderRefund/info'
    },
  }
}

export default api
