import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css/normalize.css'
import No from '@/components/no'
import './styles/reset.less'
import axios from './util/axios'
import api from './util/api'
import SlideVerify from 'vue-monoplasty-slide-verify'

//生产环境
if (process.env.NODE_ENV == 'production') {
  console.log = function () {}
}
// console.warn = function () {}
Vue.use(SlideVerify)
Vue.use(ElementUI)
Vue.use(VueCookies)
Vue.component('No', No)
Vue.prototype.$axios = axios
Vue.prototype.$api = api
Vue.prototype.$ak = 'myAKa'
Vue.config.productionTip = false
Vue.config.silent = true
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
