import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
Vue.use(VueRouter)
export const routes = [
  {
    path: '/print',
    name: 'print',
    component: () => import('@/views/Print'),
    meta: {
      rules: 0
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      rules: 0
    }
  },
  {
    path: '/service',
    name: 'service',
    component: Home,
    redirect: '/service/list',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/set1.png',
      name: '服务',
      title: '服务管理',
      rules: 1
    },
    children: [
      {
        path: '/service/list',
        name: 'service-list',
        component: () => import('@/views/service/List'),
        meta: {
          name: '服务列表',
          rules: 1
        }
      },
      {
        path: '/service/addService',
        name: 'service-addService',
        component: () => import('@/views/service/AddService'),
        meta: {
          name: '添加服务',
          rules: 1,
          form: 1
        }
      },
      {
        path: '/service/serviceInfo',
        name: 'service-serviceInfo',
        component: () => import('@/views/service/AddService'),
        meta: {
          name: '服务详情',
          relation: 'service-list',
          rules: 0,
          form: 1
        }
      },
      // {
      //   path: '/service/type',
      //   name: 'service-type',
      //   component: () => import('@/views/service/Type'),
      //   meta: {
      //     name: '服务分类',
      //     rules: 1
      //   }
      // },
      {
        path: '/service/order',
        name: 'service-order',
        component: () => import('@/views/service/Order'),
        meta: {
          name: '服务订单',
          rules: 1
        }
      },
      {
        path: '/service/orderInfo',
        name: 'service-orderInfo',
        component: () => import('@/views/service/OrderInfo'),
        meta: {
          name: '订单信息',
          relation: 'service-order',
          rules: 0
        }
      },
      {
        path: '/service/afterSale',
        name: 'service-afterSale',
        component: () => import('@/views/service/AfterSale'),
        meta: {
          name: '服务售后',
          rules: 1
        }
      },
      {
        path: '/service/afterSaleInfo',
        name: 'service-afterSaleInfo',
        component: () => import('@/views/service/AfterSaleInfo'),
        meta: {
          name: '售后信息',
          relation: 'service-afterSale',
          rules: 0
        }
      },
      {
        path: '/service/evaluate',
        name: 'service-evaluate',
        component: () => import('@/views/service/Evaluate'),
        meta: {
          name: '服务评价',
          rules: 1
        }
      }
    ]
  },
  {
    path: '/goods',
    name: 'goods',
    component: Home,
    redirect: '/goods/goodsList',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/goods.png',
      name: '商品',
      title: '商品管理',
      rules: 1
    },
    children: [
      {
        path: '/goods/goodsList',
        component: () => import('@/views/goods/GoodsList'),
        name: 'goods-goodsList',
        meta: {
          name: '商品列表',
          rules: 1
        }
      },
      {
        path: '/goods/addGoods',
        component: () => import('@/views/goods/AddGoods'),
        name: 'goods-addGoods',
        meta: {
          name: '添加商品',
          rules: 1,
          form: 1
        }
      },
      {
        path: '/goods/editGoods',
        component: () => import('@/views/goods/AddGoods'),
        name: 'goods-editGoods',
        meta: {
          name: '编辑商品',
          relation: 'goods-addGoods',
          rules: 0,
          form: 1
        }
      },
      {
        path: '/goods/editGoodsType',
        component: () => import('@/views/goods/AddType'),
        name: 'goods-editGoodsType',
        meta: {
          name: '编辑分类',
          relation: 'goods-addGoods',
          rules: 0,
          form: 1
        }
      },
      {
        path: '/goods/GoodsType',
        component: () => import('@/views/goods/GoodsType'),
        name: 'goods-goodsType',
        meta: {
          name: '商品分类',
          rules: 1
        }
      },
      {
        path: '/goods/AddType',
        component: () => import('@/views/goods/AddType'),
        name: 'goods-addType',
        meta: {
          name: '添加分类',
          rules: 1,
          form: 1
        }
      },
      {
        path: '/goods/GoodsEvaluate',
        component: () => import('@/views/goods/GoodsEvaluate'),
        name: 'goods-GoodsEvaluate',
        meta: {
          name: '商品评价',
          rules: 1
        }
      },
      {
        path: '/goods/orderList',
        component: () => import('@/views/goods/OrderList'),
        name: 'goods-orderList',
        meta: {
          name: '订单列表',
          rules: 1
        }
      },
      {
        path: '/goods/salesRefund',
        component: () => import('@/views/goods/SalesRefund'),
        name: 'goods-salesRefund',
        meta: {
          name: '售后退款',
          rules: 1
        }
      },
      {
        path: '/goods/salesHandle',
        component: () => import('@/views/goods/SalesHandle'),
        name: 'goods-salesHandle',
        meta: {
          name: '售后处理',
          relation: 'goods-salesRefund',
          rules: 0
        }
      },
      {
        path: '/goods/orderInfo',
        component: () => import('@/views/goods/OrderInfo'),
        name: 'goods-orderInfo',
        meta: {
          name: '订单信息',
          relation: 'goods-orderList',
          rules: 0
        }
      },
    ]
  },
  {
    path: '/extension',
    name: 'extension',
    component: Home,
    redirect: '/extension/couponList',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/goods.png',
      name: '营销',
      title: '营销管理',
      rules: 1
    },
    children: [
      {
        path: '/extension/couponList',
        component: () => import('@/views/extension/CouponList'),
        name: 'extension-couponList',
        meta: {
          name: '优惠券列表',
          rules: 1
        }
      },
      {
        path: '/extension/couponAdd',
        component: () => import('@/views/extension/CouponAdd'),
        name: 'extension-couponAdd',
        meta: {
          name: '添加优惠券',
          rules: 0,
          form: 1,
          relation: 'extension-couponList'
        }
      },
      {
        path: '/extension/couponEdit',
        component: () => import('@/views/extension/CouponAdd'),
        name: 'extension-couponEdit',
        meta: {
          name: '编辑优惠券',
          rules: 0,
          form: 1,
          relation: 'extension-couponList'
        }
      },
      {
        path: '/extension/goodsSet',
        name: 'extension-goodsSet',
        component: () => import('@/views/extension/GoodsSet'),
        meta: {
          name: '分销商品设置',
          rules: 1,
        }
      },
    ]
  }, {
    path: '/set',
    name: 'set',
    component: Home,
    redirect: '/set/sourceCenter',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/goods.png',
      name: '设置',
      title: '设置管理',
      rules: 1
    },
    children: [
      {
        path: '/set/sourceCenter',
        component: () => import('@/views/set/sourceCenter/index00'),
        name: 'set-sourceCenter',
        meta: {
          name: '素材中心',
          rules: 1
        }
      },
      // {
      //   path: '/set/address',
      //   name: 'set-address',
      //   component: () => import('@/views/set/Address'),
      //   meta: {
      //     name: '退货地址配置',
      //     rules: 1,
      //   }
      // },
      {
        path: '/set/freightlist',
        name: 'set-freightlist',
        component: () => import('@/views/set/Freightlist'),
        meta: {
          name: '运费配置',
          rules: 1,
        }
      },
      {
        path: '/set/freight',
        name: 'set-freight',
        component: () => import('@/views/set/Freight'),
        meta: {
          name: '添加运费',
          rules: 0,
          form: 1,
          relation: 'set-freightlist'
        }
      },
    ]
  }
]
const createRouter = () =>
  new VueRouter({
    base: process.env.BASE_URL,
    routes
  })
const router = createRouter()
router.beforeEach((to, from, next) => {
  if (to.path == '/login') {
    next()
  } else {
    let token = localStorage.getItem('token');
    if (!token) {
      router.push('/login').catch((err) => err)
    } else {
      next();
    }
  }
})
export default router
