//
//
//
//
//
//

import Loading from './components/loading.vue'

export default {
  components: {
    Loading
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  computed: {
    loading() {
      return this.$store.state.user.loadingFlag
    }
  },
  created() {
    if (localStorage.getItem('token')) {
      this.$store.dispatch('user/getUserInfo')
    }
  },
  mounted() {},
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}
