//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      is_remember: !1,
      loginForm: {
        account: '',
        password: '',
        code: '',
      },
    };
  },
  created() {
    if (sessionStorage.getItem('token')) this.$router.go(1);
    this.loginForm.account = localStorage.getItem('account');
    this.loginForm.password = localStorage.getItem('password');
    if (localStorage.getItem('password')) {
      this.is_remember = !0;
    }
  },
  methods: {
    getremember(val) {
      if (val) {
        localStorage.setItem('account', this.loginForm.account);
        localStorage.setItem('password', this.loginForm.password);
      } else {
        localStorage.removeItem('account');
        localStorage.removeItem('password');
      }
    },
    selectLoginType(i) {
      this.loginType = i;
      for (let i in this.loginForm) this.loginForm[i] = '';
    },
    //登录提交
    loginSubmit(e) {
      e.preventDefault();
      let that = this;
      let loginForm = that.loginForm;
      if (!loginForm.account) return that.$message.error('请填写账号');
      if (!loginForm.password) return that.$message.error('请填写密码');
      //账号密码登录
      that.$axios
        .post(that.$api.user.login, {
          account: loginForm.account,
          password: loginForm.password,
        })
        .then((res) => {
          if (res.code == 0) {
            that.$message({
              message: '登录成功',
              type: 'success',
            });
            that.getremember(that.is_remember);
            localStorage.setItem('token', res.result.token);
            this.$store.dispatch('user/getUserInfo')
            that.$router.push('/service');
          } else {
            that.$message.error(res.msg);
          }
        });
    },
  },
};
