//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {}
  },
  props: {
    text: {
      default: '暂无内容'
    }
  }
}
